import { RegistrationDtoVerificationState } from '../api/generated/model';

export function getDecisionText(decision: RegistrationDtoVerificationState) {
  switch (decision) {
    case RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CORRECT:
      return 'HAM bestätigt';
    case RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE:
      return 'Wechsel zu HAM bestätigt';
    case RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CHANGE_NEEDED:
      return 'HAM bestätigt - Wechsel v. Arzt notwendig';
    case RegistrationDtoVerificationState.MODEL_WRONG:
      return 'Kein HAM';
    case RegistrationDtoVerificationState.UNCLEAR:
      return 'Unklar';
    case RegistrationDtoVerificationState.INITIAL:
      return 'Kein Entscheid';
    default:
      return null;
  }
}
