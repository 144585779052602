/**
 * HAM API – Generated by orval 🍺
 * Do not edit manually.
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ErrorType } from '../../axios';
import { request } from '../../axios';
import type { FileDto, ListPatientRegistrationDetailsParams, ModelCheckPageDto } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const listPatientRegistrationDetails = (
  params: ListPatientRegistrationDetailsParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<ModelCheckPageDto>(
    { url: `/intranet-api/registration-details`, method: 'GET', params, signal },
    options,
  );
};

export const getListPatientRegistrationDetailsQueryKey = (params: ListPatientRegistrationDetailsParams) => {
  return [`/intranet-api/registration-details`, ...(params ? [params] : [])] as const;
};

export const getListPatientRegistrationDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof listPatientRegistrationDetails>>,
  TError = ErrorType<unknown>,
>(
  params: ListPatientRegistrationDetailsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listPatientRegistrationDetails>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPatientRegistrationDetailsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPatientRegistrationDetails>>> = ({ signal }) =>
    listPatientRegistrationDetails(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPatientRegistrationDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPatientRegistrationDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPatientRegistrationDetails>>
>;
export type ListPatientRegistrationDetailsQueryError = ErrorType<unknown>;

export const useListPatientRegistrationDetails = <
  TData = Awaited<ReturnType<typeof listPatientRegistrationDetails>>,
  TError = ErrorType<unknown>,
>(
  params: ListPatientRegistrationDetailsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listPatientRegistrationDetails>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPatientRegistrationDetailsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getRegistrationFileById = (
  registrationId: string,
  fileId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<FileDto>(
    { url: `/intranet-api/registration-details/${registrationId}/file/${fileId}`, method: 'GET', signal },
    options,
  );
};

export const getGetRegistrationFileByIdQueryKey = (registrationId: string, fileId: string) => {
  return [`/intranet-api/registration-details/${registrationId}/file/${fileId}`] as const;
};

export const getGetRegistrationFileByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getRegistrationFileById>>,
  TError = ErrorType<unknown>,
>(
  registrationId: string,
  fileId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFileById>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRegistrationFileByIdQueryKey(registrationId, fileId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegistrationFileById>>> = ({ signal }) =>
    getRegistrationFileById(registrationId, fileId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(registrationId && fileId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRegistrationFileById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRegistrationFileByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationFileById>>>;
export type GetRegistrationFileByIdQueryError = ErrorType<unknown>;

export const useGetRegistrationFileById = <
  TData = Awaited<ReturnType<typeof getRegistrationFileById>>,
  TError = ErrorType<unknown>,
>(
  registrationId: string,
  fileId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFileById>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRegistrationFileByIdQueryOptions(registrationId, fileId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
