import {
  Alert,
  CircularProgress,
  FormControl,
  FormLabel,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from '@mui/joy';
import { useGetTemplates } from '../api/generated/template-resource/template-resource.ts';
import { AdminHeader } from '../components/AdminHeader.tsx';
import { ContentSection } from '../components/ContentSection.tsx';
import { ImageEditor } from '../components/templates/ImageEditor.tsx';
import { TextEditor } from '../components/templates/TextEditor.tsx';
import { theme } from '../theme.ts';

export function Templates() {
  const { data: templates, isError, isPending, refetch } = useGetTemplates();

  return (
    <ContentSection>
      <AdminHeader>Templates</AdminHeader>
      {isPending ? (
        <CircularProgress />
      ) : isError ? (
        <Alert color="danger">
          Leider können die Templates momentan nicht geladen werden. Bitte versuchen Sie es in ein paar Minuten erneut.
        </Alert>
      ) : (
        <Tabs
          defaultValue={templates[0]?.id}
          orientation="vertical"
          sx={{
            background: 'none',
            border: `solid 1px ${theme.palette.divider}`,
            borderRadius: theme.radius.md,
            overflow: 'hidden',
            flex: 1,
            display: 'grid',
            gridTemplateColumns: '280px minmax(0, 1fr)',
            gridTemplateRows: 'minmax(0, 1fr)',
          }}>
          <TabList sx={{ background: theme.palette.background.surface, paddingBlock: 1 }}>
            {templates.map((section) => (
              <Tab value={section.id} key={section.id}>
                {section.title}
              </Tab>
            ))}
          </TabList>
          {templates.map((section) => (
            <TabPanel value={section.id} key={section.id} sx={{ overflowY: 'scroll' }}>
              <Stack gap={2}>
                {[
                  ...section.texts.map((text) => ({ type: 'text' as const, ...text })),
                  ...section.images.map((image) => ({ type: 'image' as const, ...image })),
                ]
                  .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                  .map((item) => (
                    <FormControl key={item.id}>
                      <FormLabel>
                        <Typography>{item.label}</Typography>
                      </FormLabel>
                      {item.type === 'text' ? (
                        <TextEditor sectionId={section.id} text={item} onUpdate={refetch} />
                      ) : (
                        <ImageEditor sectionId={section.id} image={item} />
                      )}
                    </FormControl>
                  ))}
              </Stack>
            </TabPanel>
          ))}
        </Tabs>
      )}
    </ContentSection>
  );
}
