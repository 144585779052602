import { styled, Table } from '@mui/joy';

export const TableWithForm = styled(Table)`
  --TableCell-headBackground: var(--joy-palette-background-surface);
  --TableCell-height: 40px;
  & thead tr th {
    height: auto;
    top: 49px;
    --TableCell-cornerRadius: 0;
  }

  // last row except when there is no data
  & tbody tr:last-of-type > td:not([colspan]) {
    // box-shadow instead of border-bottom to prevent showing two borders (last table row and container outline) next to each other when the user scrolls down to the bottom of the table
    box-shadow: 0 1px 0 var(--TableCell-borderColor);
    height: calc(var(--TableCell-height) - 1px);
  }
`;
