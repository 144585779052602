/**
 * HAM API – Generated by orval 🍺
 * Do not edit manually.
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ErrorType } from '../../axios';
import { request } from '../../axios';
import type { HamUserDto } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const whoami = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<HamUserDto>({ url: `/intranet-api/whoami`, method: 'GET', signal }, options);
};

export const getWhoamiQueryKey = () => {
  return [`/intranet-api/whoami`] as const;
};

export const getWhoamiQueryOptions = <
  TData = Awaited<ReturnType<typeof whoami>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getWhoamiQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof whoami>>> = ({ signal }) => whoami(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof whoami>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WhoamiQueryResult = NonNullable<Awaited<ReturnType<typeof whoami>>>;
export type WhoamiQueryError = ErrorType<unknown>;

export const useWhoami = <TData = Awaited<ReturnType<typeof whoami>>, TError = ErrorType<unknown>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof whoami>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWhoamiQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
