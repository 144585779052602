import { Tooltip } from '@mui/joy';
import { HTMLProps, useRef, useState } from 'react';

export function TooltipCell({ children, ...tdProps }: HTMLProps<HTMLTableCellElement>) {
  const tdRef = useRef<HTMLTableCellElement>(null);
  const [open, setOpen] = useState(false);

  return (
    <td {...tdProps} ref={tdRef}>
      <Tooltip
        title={children}
        arrow={false}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => {
          const isTruncated = tdRef.current && tdRef.current.scrollWidth > tdRef.current.clientWidth;
          if (isTruncated) {
            setOpen(true);
          }
        }}>
        <span>{children}</span>
      </Tooltip>
    </td>
  );
}
