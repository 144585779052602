import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { DialogActions, DialogContent, IconButton } from '@mui/joy';
import { RefObject, useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useDebouncedCallback } from '../hooks/debouncedResizeObserver.ts';

export function PdfViewer({ fileUrl }: { fileUrl: string }) {
  const [filePages, setFilePages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const dialogWidth = useDialogWidth(dialogContentRef, 200);

  return (
    <>
      <DialogContent ref={dialogContentRef}>
        <Document file={fileUrl} onLoadSuccess={(pdf) => setFilePages(pdf.numPages)} loading="PDF wird geladen…">
          <Page width={dialogWidth} pageNumber={currentPage} />
        </Document>
      </DialogContent>
      {filePages > 1 && (
        <DialogActions>
          <IconButton onClick={() => setCurrentPage(currentPage + 1)} disabled={filePages <= currentPage}>
            <ArrowForwardIos />
          </IconButton>
          <IconButton onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
            <ArrowBackIosNew />
          </IconButton>
        </DialogActions>
      )}
    </>
  );
}

interface ResizeObserverEntry {
  target: Element;
  contentRect: DOMRectReadOnly;
}

type ResizeObserverCallback = (entries: ResizeObserverEntry[]) => void;

function useDialogWidth(ref: RefObject<HTMLDivElement>, delay: number) {
  const [width, setWidth] = useState(0);

  const handleResize: ResizeObserverCallback = ([entry]: ResizeObserverEntry[]) => {
    const { width } = entry.contentRect;
    setWidth(width);
  };

  const debouncedHandleResize = useDebouncedCallback(handleResize, delay);

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver(debouncedHandleResize);
    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, debouncedHandleResize]);

  return width;
}
