import { LinearProgress, Sheet, Stack } from '@mui/joy';
import { ReactNode } from 'react';

export function ScrollableTableContainer({
  children,
  actionsLeft,
  actionsRight,
  isLoading,
}: {
  children: ReactNode;
  actionsLeft: ReactNode;
  actionsRight: ReactNode;
  isLoading: boolean;
}) {
  return (
    <Sheet variant="outlined" sx={{ flex: 1, overflow: 'hidden', borderRadius: 4, display: 'flex', bgcolor: 'white' }}>
      <Stack style={{ overflowY: 'scroll' }}>
        <Sheet
          sx={{
            borderBottom: '1px solid var(--joy-palette-divider)',
            padding: '0.375rem 0.5rem',
            position: 'sticky',
            top: 0,
            zIndex: 10,
          }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap={1} alignItems="center">
              {actionsLeft}
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
              {actionsRight}
            </Stack>
          </Stack>
          {isLoading && (
            <LinearProgress
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: -1,
                '--LinearProgress-thickness': '1px',
                bgcolor: 'transparent',
              }}
            />
          )}
        </Sheet>
        {children}
      </Stack>
    </Sheet>
  );
}
