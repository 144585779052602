import { styled } from '@mui/joy';
import { Route, Routes } from 'react-router-dom';
import { ArgomedClaimsInstitutionType } from './api/generated/model';
import { useWhoami } from './api/generated/whoami-resource/whoami-resource.ts';
import { DevTools } from './components/DevTools.tsx';
import { Decision } from './pages/Decision.tsx';
import { ModelCheck } from './pages/ModelCheck.tsx';
import { PracticeTable } from './pages/PracticeTable.tsx';
import { Templates } from './pages/Templates.tsx';
import { isInIFrame } from './utils/isInIFrame.ts';

export function App() {
  const whoami = useWhoami({ query: { retry: false } });
  const institutionType = whoami.data?.argomedClaims.institution_type;
  if (!institutionType) {
    return null;
  }
  const showAdminPages =
    institutionType === ArgomedClaimsInstitutionType.ADMIN || institutionType === ArgomedClaimsInstitutionType.SUPPORT;

  return (
    <Wrapper>
      {!isInIFrame && <DevTools />}
      <Routes>
        <Route path="/" element={showAdminPages ? <ModelCheck /> : <PracticeTable />} />
        {showAdminPages && (
          <>
            <Route path={'/templates'} element={<Templates />} />
            <Route path={'/registration/:registrationId/decision/:decision/'} element={<Decision />} />
          </>
        )}
      </Routes>
    </Wrapper>
  );
}

const Wrapper = styled('div')`
  display: grid;
  grid-template-rows: auto 1fr;
`;
