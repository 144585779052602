import { CloudUpload } from '@mui/icons-material';
import { Button, CircularProgress, Stack, styled } from '@mui/joy';
import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { useUpdateTemplateImage } from '../../api/generated/template-resource/template-resource';

const MAX_FILE_SIZE = 15000000;

interface Props {
  sectionId: string;
  imageId: string;
  onUpload: () => void;
}

export function FileUpload({ sectionId, imageId, onUpload }: Props) {
  const [fileUploadProgress, setFileUploadProgress] = useState<null | number>(null);
  const updateTemplateImageMutation = useUpdateTemplateImage({
    mutation: {
      onSettled: () => setFileUploadProgress(null),
      onSuccess: onUpload,
      onError: () => toast.error('Beim Hochladen ist ein unerwarteter Fehler aufgetreten'),
    },
    request: {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) setFileUploadProgress(progressEvent.loaded / progressEvent.total);
      },
    },
  });

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      if (event.target.files[0].size <= MAX_FILE_SIZE) {
        updateTemplateImageMutation.mutate({
          sectionId,
          imageId,
          data: {
            file: event.target.files[0],
          },
        });
      } else {
        toast.error('Die maximale Dateigrösse beträgt 15MB');
      }
      event.target.value = '';
    }
  };

  return (
    <Button
      sx={{ flexShrink: 0, alignSelf: 'start' }}
      component="label"
      variant="outlined"
      color="neutral"
      loading={updateTemplateImageMutation.isPending}
      startDecorator={<CloudUpload />}
      loadingIndicator={
        fileUploadProgress ? (
          <Stack direction="row" gap={1}>
            <CircularProgress determinate value={fileUploadProgress * 100} />
            {Math.round(fileUploadProgress * 100)}%
          </Stack>
        ) : (
          <CircularProgress />
        )
      }>
      Bild ersetzen
      <VisuallyHiddenInput
        type="file"
        accept="image/jpeg, image/png, image/svg+xml"
        onChange={(event) => handleFileUpload(event)}
      />
    </Button>
  );
}

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;
