import { Stack, styled } from '@mui/joy';
import { useState } from 'react';
import { Image } from '../../api/generated/model';
import { theme } from '../../theme.ts';
import { FileUpload } from './FileUpload.tsx';

export function ImageEditor({ sectionId, image: { id, label } }: { sectionId: string; image: Image }) {
  const [imageUpdatedAt, setImageUpdatedAt] = useState(Date.now());

  return (
    <Stack gap={1}>
      <FileUpload sectionId={sectionId} imageId={id} onUpload={() => setImageUpdatedAt(Date.now())} />
      <UploadedImageContainer>
        <UploadedImage
          alt={label}
          src={`/intranet-api/templates/section/${sectionId}/image/${id}?now=${imageUpdatedAt}`}
        />
      </UploadedImageContainer>
    </Stack>
  );
}

const UploadedImageContainer = styled(Stack)`
  align-self: start;
  background: ${theme.palette.background.surface};
  border-radius: ${theme.radius.sm};
  border: 1px solid ${theme.palette.neutral.outlinedBorder};
  padding: 0.75rem;
`;

const UploadedImage = styled('img')`
  align-self: start;
  max-width: 100%;
`;
