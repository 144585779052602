import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { ButtonGroup, IconButton, Typography } from '@mui/joy';
import { useEffect } from 'react';

export function Pagination({
  pageDto,
  page,
  setPage,
  pageSize,
}: {
  pageDto?: { totalCount: number };
  page: number;
  setPage: (value: number) => void;
  pageSize: number;
}) {
  // reset page back to max page if current page has no data (can happen if user searches while not being on page 0)
  useEffect(() => {
    if (pageDto) {
      const maxPage = Math.max(0, Math.ceil(pageDto.totalCount / pageSize) - 1);
      if (page > maxPage) {
        setPage(maxPage);
      }
    }
  }, [pageDto, page, setPage, pageSize]);

  return (
    <>
      {pageDto && (
        <Typography>
          {pageDto.totalCount && page * pageSize + 1}-{Math.min((page + 1) * pageSize, pageDto.totalCount)} /{' '}
          {pageDto.totalCount}
        </Typography>
      )}
      <ButtonGroup>
        <IconButton onClick={() => setPage(page - 1)} disabled={page === 0} variant="outlined">
          <ArrowBackIosNew />
        </IconButton>
        <IconButton
          onClick={() => setPage(page + 1)}
          disabled={pageDto ? pageDto.totalCount <= (page + 1) * pageSize : true}
          variant="outlined">
          <ArrowForwardIos />
        </IconButton>
      </ButtonGroup>
    </>
  );
}
