import { CircularProgress, DialogContent, Modal, ModalClose, ModalDialog, Typography } from '@mui/joy';
import heic2any from 'heic2any';
import { useEffect, useState } from 'react';
import { FileDto } from '../api/generated/model';
import { useGetRegistrationFileById } from '../api/generated/registration-detail-resource/registration-detail-resource.ts';
import { PdfViewer } from './PdfViewer.tsx';

export function DocumentModal({
  open,
  onClose,
  registrationId,
  fileId,
}: {
  open: boolean;
  onClose: () => void;
  registrationId: string;
  fileId: string;
}) {
  const { data: file } = useGetRegistrationFileById(registrationId, fileId);

  const [fileUrl, setFileUrl] = useState('');

  const getFileUrl = async (file: FileDto) => {
    const fileByteArray = Uint8Array.from(atob(file.content), (c) => c.charCodeAt(0));
    if (file.type === 'image/heic') {
      const pngResult = await heic2any({
        blob: new Blob([fileByteArray]),
        toType: 'image/png',
      });
      const blob = Array.isArray(pngResult) ? pngResult[0] : pngResult;
      return URL.createObjectURL(blob);
    }

    const blob = new Blob([fileByteArray], { type: file.content });
    return URL.createObjectURL(blob);
  };

  useEffect(() => {
    let cancelled = false;
    let url = '';
    void (async () => {
      if (file) {
        url = await getFileUrl(file);
        if (!cancelled) {
          setFileUrl(url);
        } else {
          URL.revokeObjectURL(url);
        }
      }
    })();
    return () => {
      cancelled = true;
      URL.revokeObjectURL(url);
      setFileUrl('');
    };
  }, [file]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog sx={{ width: 1200, height: 'calc(100% - 2 * var(--Card-padding))' }}>
        <ModalClose />
        <Typography
          noWrap
          sx={{
            minHeight: 28,
            marginRight: 4,
          }}>
          {file?.name}
        </Typography>
        {file &&
          (file.type === 'application/pdf' ? (
            <PdfViewer fileUrl={fileUrl} />
          ) : (
            <DialogContent>
              {fileUrl ? (
                <img style={{ maxWidth: '100%' }} src={fileUrl} alt={file.name} />
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </div>
              )}
            </DialogContent>
          ))}
      </ModalDialog>
    </Modal>
  );
}
