import { Person } from '@mui/icons-material';
import { Alert, Button, Chip, Sheet, Skeleton, Tooltip, Typography } from '@mui/joy';
import { ArgomedClaims } from '../api/generated/model';
import { useWhoami } from '../api/generated/whoami-resource/whoami-resource.ts';

export function DevTools() {
  const whoami = useWhoami({ query: { retry: false } });
  return (
    <Alert
      sx={{
        borderRadius: '0 0 8px 8px',
        position: 'fixed',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 20,
        boxShadow: 'var(--joy-shadow-sm), var(--joy-shadow-lg)',
      }}
      color={whoami.isError ? 'danger' : 'neutral'}
      endDecorator={
        whoami.isSuccess && (
          <Button size="sm" color="neutral" onClick={() => (window.location.href = '/intranet-api/logout')}>
            Logout
          </Button>
        )
      }>
      {whoami.isError ? (
        <Typography color="danger">
          {whoami.error.response
            ? `${whoami.error.response.status} ${whoami.error.response.statusText}`
            : whoami.error.message}
        </Typography>
      ) : whoami.isPending ? (
        <Typography>
          <Skeleton>Loading...</Skeleton>
        </Typography>
      ) : (
        <DevToolsUser claims={whoami.data.argomedClaims} />
      )}
    </Alert>
  );
}

function DevToolsUser({ claims }: { claims: ArgomedClaims }) {
  const { member_name, institution_id, institution_type, institution_name, member_profile, praxis_zsr, member_zsr } =
    claims;

  return (
    <Tooltip
      title={
        <Sheet variant="solid" invertedColors>
          <Typography>
            {member_profile} <small>(Member Profil)</small>
          </Typography>
          <Typography>
            {institution_id} {institution_name} {institution_type} <small>(Institution)</small>
          </Typography>
          <Typography>
            {praxis_zsr?.toString() ?? 'N/A'} <small>(Praxis ZSR)</small>
          </Typography>
          <Typography>
            {member_zsr ?? 'N/A'} <small>(Member ZSR)</small>
          </Typography>
        </Sheet>
      }>
      <Chip variant="outlined" size="lg" startDecorator={<Person />}>
        {member_name}
      </Chip>
    </Tooltip>
  );
}
