import { AccessTimeFilled, CheckCircle, Search } from '@mui/icons-material';
import { Button, Checkbox, Input, Typography } from '@mui/joy';
import { keepPreviousData } from '@tanstack/react-query';
import { isFuture, parseISO, subMonths } from 'date-fns';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { RegistrationDtoVerificationState } from '../api/generated/model';
import {
  useGetPracticeTableIntro,
  useListPatientRegistrationDetailsForPractice,
  useSetAdmittedStateForRegistration,
} from '../api/generated/practice-registration-resource/practice-registration-resource.ts';
import { CheckboxButton } from '../components/CheckboxButton.tsx';
import { ContentSection } from '../components/ContentSection.tsx';
import { Pagination } from '../components/Pagination.tsx';
import { ScrollableTableContainer } from '../components/ScrollableTableContainer.tsx';
import { TableWithForm } from '../components/TableWithForm.tsx';
import { TooltipCell } from '../components/TooltipCell.tsx';
import { formatDate } from '../utils/date.ts';
import { getSearchText } from '../utils/getSearchText.ts';

const PAGE_SIZE = 50;

export function PracticeTable() {
  const [showAdmittedRegistrations, setShowAdmittedRegistrations] = useState(false);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const practiceTableIntro = useGetPracticeTableIntro();

  const {
    data: pageDto,
    isRefetching: isPageDtoRefetching,
    refetch: refetchPatientList,
  } = useListPatientRegistrationDetailsForPractice(
    {
      page: page,
      size: PAGE_SIZE,
      showAdmitted: showAdmittedRegistrations,
      searchParam: getSearchText(searchText),
    },
    { query: { placeholderData: keepPreviousData } },
  );

  const setAdmittedForRegistrationMutation = useSetAdmittedStateForRegistration();

  const handleAdmittedStateChange = (id: string, isAdmitted: boolean, firstname: string, lastname: string) => {
    setAdmittedForRegistrationMutation.mutate(
      {
        registrationId: id,
        params: { isAdmitted: !isAdmitted },
      },
      {
        onSuccess: (_, variables) => {
          void refetchPatientList();
          toast.success(
            <>
              {variables.params.isAdmitted ? (
                <div>
                  <Typography>
                    Patient {firstname} {lastname} wurde als erledigt markiert.
                  </Typography>
                  <Button
                    variant={'plain'}
                    color={'warning'}
                    onClick={() =>
                      handleAdmittedStateChange(
                        variables.registrationId,
                        variables.params.isAdmitted,
                        firstname,
                        lastname,
                      )
                    }>
                    Rückgängig machen
                  </Button>
                </div>
              ) : (
                <Typography>
                  Patient {firstname} {lastname} wurde als nicht erledigt markiert.
                </Typography>
              )}
            </>,
            { autoClose: 10000 },
          );
        },
        onError: () => toast.error('Beim Markieren des Patienten als erledigt ist ein unerwarteter Fehler aufgetreten'),
      },
    );
  };

  return (
    <ContentSection>
      <Typography level="h2" component="h1">
        Folgende Patienten wurden durch Argomed überprüft:
      </Typography>
      {practiceTableIntro.data && <Typography sx={{ mb: 2 }}>{practiceTableIntro.data}</Typography>}
      <ScrollableTableContainer
        actionsLeft={
          <>
            <Input
              value={searchText}
              startDecorator={<Search />}
              placeholder={'Patienten suchen'}
              onChange={(event) => setSearchText(event.target.value)}
            />
            <CheckboxButton
              label={'Erledigte anzeigen'}
              checked={showAdmittedRegistrations}
              onChange={() => setShowAdmittedRegistrations(!showAdmittedRegistrations)}
            />
          </>
        }
        actionsRight={<Pagination pageDto={pageDto} page={page} setPage={setPage} pageSize={PAGE_SIZE} />}
        isLoading={isPageDtoRefetching}>
        <TableWithForm stickyHeader borderAxis={'bothBetween'} noWrap>
          <thead>
            <tr>
              <th>Name Vorname</th>
              <th>Geburtsdatum</th>
              <th>Eingegangen am</th>
              <th>Hausarztmodell geprüft</th>
              <th>Erledigt</th>
            </tr>
          </thead>
          <tbody>
            {pageDto?.registrationDetails && pageDto.registrationDetails.length > 0 ? (
              pageDto.registrationDetails.map((row) => (
                <tr key={row.registration.id}>
                  <TooltipCell>
                    {row.registration.firstName} {row.registration.lastName}
                  </TooltipCell>
                  <td>{formatDate(parseISO(row.registration.birthDate))}</td>
                  <td>{formatDate(parseISO(row.state.created))}</td>
                  <td>
                    <ModelCheckedStatus
                      verificationState={row.registration.verificationState}
                      acceptedFrom={row.state.stateData.acceptedFrom}
                    />
                  </td>
                  <td>
                    <Checkbox
                      checked={row.registration.isAdmitted ? row.registration.isAdmitted : false}
                      onChange={() =>
                        handleAdmittedStateChange(
                          row.registration.id,
                          row.registration.isAdmitted ? row.registration.isAdmitted : false,
                          row.registration.firstName,
                          row.registration.lastName,
                        )
                      }
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>
                  <Typography level={'h3'}>Keine Daten</Typography>
                </td>
              </tr>
            )}
          </tbody>
        </TableWithForm>
      </ScrollableTableContainer>
    </ContentSection>
  );
}

function ModelCheckedStatus({
  verificationState,
  acceptedFrom,
}: {
  verificationState: RegistrationDtoVerificationState;
  acceptedFrom?: string | null;
}) {
  if (
    acceptedFrom &&
    isFuture(acceptedFrom) &&
    verificationState == RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE
  ) {
    const isMoreThanTwoMonthsInTheFuture = isFuture(subMonths(acceptedFrom, 2));
    return (
      <Typography
        startDecorator={
          <AccessTimeFilled
            sx={{
              fontSize: 18,
              color: isMoreThanTwoMonthsInTheFuture
                ? 'var(--joy-palette-warning-400)'
                : 'var(--joy-palette-success-400)',
            }}
          />
        }>
        ab {formatDate(parseISO(acceptedFrom))}
      </Typography>
    );
  }
  return <Typography startDecorator={<CheckCircle sx={{ fontSize: 18, color: 'var(--joy-palette-success-400)' }} />} />;
}
