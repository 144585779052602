/**
 * HAM API – Generated by orval 🍺
 * Do not edit manually.
 */

export type RegistrationDtoVerificationState =
  (typeof RegistrationDtoVerificationState)[keyof typeof RegistrationDtoVerificationState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegistrationDtoVerificationState = {
  INITIAL: 'INITIAL',
  MODEL_CORRECT_DOCTOR_CORRECT: 'MODEL_CORRECT_DOCTOR_CORRECT',
  MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE: 'MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE',
  MODEL_CORRECT_DOCTOR_CHANGE_NEEDED: 'MODEL_CORRECT_DOCTOR_CHANGE_NEEDED',
  MODEL_WRONG: 'MODEL_WRONG',
  UNCLEAR: 'UNCLEAR',
} as const;
