import { Link, Stack, Typography } from '@mui/joy';
import { ReactNode } from 'react';
import { LinkProps, NavLink } from 'react-router-dom';

export function AdminHeader({ children }: { children: ReactNode }) {
  return (
    <Stack direction="row" alignItems="baseline" justifyContent="space-between">
      <Typography level="h1">{children}</Typography>
      <Stack direction="row" gap={1}>
        <HeaderLink to="/">Überprüfung Versicherungsmodell</HeaderLink>
        <HeaderLink to="/templates">Templates</HeaderLink>
      </Stack>
    </Stack>
  );
}

function HeaderLink({ children, to }: { children: ReactNode; to: LinkProps['to'] }) {
  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <Link component="span" disabled={isActive}>
          {children}
        </Link>
      )}
    </NavLink>
  );
}
