import { styled } from '@mui/joy';
import { ReactNode } from 'react';
import { isInIFrame } from '../utils/isInIFrame.ts';

export function ContentSection({ className, children }: { className?: string; children: ReactNode }) {
  return (
    <ContentWrapper className={className}>
      <div>{children}</div>
    </ContentWrapper>
  );
}

const ContentWrapper = styled('section')`
  display: grid;
  grid-template-columns: minmax(auto, 1220px);
  justify-content: center;
  height: 100vh;
  > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    padding: ${isInIFrame ? '0' : '0 10px 10px'};
    min-height: 0;
  }
`;
