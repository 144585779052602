/**
 * HAM API – Generated by orval 🍺
 * Do not edit manually.
 */

export type ArgomedClaimsInstitutionType =
  (typeof ArgomedClaimsInstitutionType)[keyof typeof ArgomedClaimsInstitutionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ArgomedClaimsInstitutionType = {
  PRAXIS: 'PRAXIS',
  ADMIN: 'ADMIN',
  SUPPORT: 'SUPPORT',
} as const;
