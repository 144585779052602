import { keyframes } from '@emotion/react';
import { ContentCopy } from '@mui/icons-material';
import { Button, Chip, Stack, Textarea, Tooltip, Typography, chipClasses, styled } from '@mui/joy';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Placeholder, Text } from '../../api/generated/model';
import { useUpdateTemplateText } from '../../api/generated/template-resource/template-resource.ts';

export function TextEditor({
  sectionId,
  text: { id: textId, content, placeholders },
  onUpdate,
}: {
  sectionId: string;
  text: Text;
  onUpdate: () => void;
}) {
  const {
    register,
    resetField,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, errors },
  } = useForm({ defaultValues: { content } });
  const watchedContent = watch('content');

  useEffect(() => reset({ content }), [content, reset]);

  const updateTemplateTextMutation = useUpdateTemplateText({
    mutation: {
      onSuccess: onUpdate,
      onError: () => toast.error('Beim Speichern ist ein unerwarteter Fehler aufgetreten'),
    },
  });

  return (
    <form
      noValidate
      onSubmit={handleSubmit(
        (data) => {
          if (updateTemplateTextMutation.isPending) return;
          updateTemplateTextMutation.mutate({
            sectionId,
            textId,
            data,
          });
        },
        (errors) =>
          toast.error(
            errors.content ? errors.content.message : 'Beim Speichern ist ein unerwarteter Fehler aufgetreten',
          ),
      )}>
      <Textarea
        error={!!errors.content}
        minRows={3}
        {...register('content', { validate: validatePlaceholderSyntax(placeholders) })}
        endDecorator={
          <Stack direction="row" gap={1} flex={1} justifyContent="space-between">
            <Stack direction="row" gap={1}>
              {placeholders
                .sort((a, b) => a.order - b.order)
                .map((placeholder) => {
                  const isPlaceholderUsed = watchedContent.includes(getTemplateTag(placeholder.name));
                  return (
                    <Tooltip
                      key={placeholder.id}
                      title={
                        <Stack gap={0.5}>
                          <Typography fontFamily="code" variant="soft" sx={{ alignSelf: 'start' }}>
                            {getTemplateTag(placeholder.name)}
                          </Typography>
                          {placeholder.label}
                        </Stack>
                      }
                      size="lg"
                      variant="outlined"
                      arrow>
                      <AnimatedDecoratorChip
                        startDecorator={<ContentCopy />}
                        onClick={() => navigator.clipboard.writeText(getTemplateTag(placeholder.name))}
                        variant={!isPlaceholderUsed && placeholder.required ? 'solid' : 'outlined'}
                        color={isPlaceholderUsed ? 'success' : placeholder.required ? 'danger' : 'neutral'}>
                        {placeholder.name}
                      </AnimatedDecoratorChip>
                    </Tooltip>
                  );
                })}
            </Stack>
            <Stack direction="row" gap={1}>
              <Button
                color="neutral"
                variant="soft"
                disabled={!isDirty || updateTemplateTextMutation.isPending}
                onClick={() => resetField('content')}>
                Zurücksetzen
              </Button>
              <Button type="submit" disabled={!isDirty} loading={updateTemplateTextMutation.isPending}>
                Speichern
              </Button>
            </Stack>
          </Stack>
        }
      />
    </form>
  );
}

function validatePlaceholderSyntax(placeholders: Placeholder[]) {
  const placeholderRegex = /\$\{[^}]+\}/g;
  return {
    requiredPlaceholders(content: string) {
      const missingPlaceholders = placeholders.filter(
        (placeholder) => placeholder.required && !content.includes(getTemplateTag(placeholder.name)),
      );
      if (missingPlaceholders.length > 0) {
        const singular = missingPlaceholders.length === 1;
        return `Das Template kann nicht gespeichert werden, da ${singular ? 'der erforderliche Platzhalter' : 'die erforderlichen Platzhalter'} ${missingPlaceholders.map(({ name }) => `"${name}"`).join(', ')} ${singular ? 'fehlt' : 'fehlen'}`;
      }
      return true;
    },
    placeholderFormat(content: string) {
      const validPlaceholders = placeholders.map((placeholder) => getTemplateTag(placeholder.name));
      const potentialPlaceholders = content.match(placeholderRegex) ?? [];
      const invalidPlaceholders = potentialPlaceholders.filter(
        (placeholder) => !validPlaceholders.includes(placeholder),
      );
      if (invalidPlaceholders.length > 0) {
        const singular = invalidPlaceholders.length === 1;
        return `Das Template kann nicht gespeichert werden, da ${singular ? 'ein ungültiger' : 'ungültige'} Platzhalter (${invalidPlaceholders.join(', ')}) gefunden ${singular ? 'wurde' : 'wurden'}`;
      }
      return true;
    },
    superfluousCharacters(content: string) {
      const cleanedContent = content.replace(placeholderRegex, '');
      const superfluousChars = cleanedContent.match(/[${}]/g);
      if (superfluousChars && superfluousChars.length > 0) {
        const oneChar = superfluousChars.length === 1;
        const setOfSuperfluousChars = [...new Set(superfluousChars).values()];
        return `Das Template kann nicht gespeichert werden, da ${oneChar ? 'ein überflüssiges' : 'überflüssige'} Platzhalter-Zeichen (${setOfSuperfluousChars.map((char) => `"${char}"`).join(', ')}) gefunden wurden`;
      }
      return true;
    },
  };
}

const getTemplateTag = (name: string) => `\${${name}}`;

const AnimatedDecoratorChip = styled(Chip)`
  .${chipClasses.startDecorator} {
    transition: scale 0.1s;
  }
  :active .${chipClasses.startDecorator} {
    animation: ${keyframes` from {transform: scale(1.3);} to {transform: scale(1);}`} 0.2s;
  }
`;
