import { useRef } from 'react';

type Callback<T extends unknown[]> = (...args: T) => void;

export function useDebouncedCallback<T extends unknown[]>(callback: Callback<T>, delay: number): (...args: T) => void {
  const timeoutRef = useRef<number | null>(null);

  return (...args: T) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };
}
