import { UTCDateMini } from '@date-fns/utc';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  styled,
  Textarea,
  Typography,
} from '@mui/joy';
import { useQueryClient } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EmailTemplateDto, RegistrationDetailsDto, RegistrationDtoVerificationState } from '../api/generated/model';
import {
  getGetRegistrationDetailsByIdQueryKey,
  useGetRegistrationDetailsById,
  useSetDecision,
} from '../api/generated/registration-decision-ressource/registration-decision-ressource.ts';
import { useGetEmailTemplateForState } from '../api/generated/template-resource/template-resource.ts';
import { ContentSection } from '../components/ContentSection.tsx';
import { useRequiredParams } from '../hooks/useRequiredParams.ts';
import { theme } from '../theme.ts';
import { formatDate } from '../utils/date.ts';
import { getDecisionText } from '../utils/decision.ts';

interface Params {
  [key: string]: string | undefined;
  decision: RegistrationDtoVerificationState;
  registrationId: string;
}
export function Decision() {
  const { decision, registrationId } = useRequiredParams<Params>();
  const navigate = useNavigate();

  const {
    data: registrationDetails,
    isPending: registrationIsPending,
    isError: registrationIsError,
  } = useGetRegistrationDetailsById(registrationId);

  const {
    data: emailTemplate,
    isPending: emailTemplateIsPending,
    isError: emailTemplateIsError,
  } = useGetEmailTemplateForState(decision, { registrationId: registrationId });

  return (
    <ContentSection>
      <div>
        <Button onClick={() => navigate('/')}>Zurück</Button>
      </div>
      {registrationIsPending || emailTemplateIsPending ? (
        <CircularProgress />
      ) : registrationIsError || emailTemplateIsError ? (
        <Typography>Ein unbekannter Fehler ist aufgetreten</Typography>
      ) : (
        <>
          <InformationBanner>
            <div>
              <Typography>Patient</Typography>
              <Typography level={'h4'}>
                {registrationDetails.registration.firstName} {registrationDetails.registration.lastName} (
                {registrationDetails.registration.email})
              </Typography>
            </div>
            <div>
              <Typography>Entscheid</Typography>
              <Typography level={'h4'}>{getDecisionText(decision)}</Typography>
            </div>
          </InformationBanner>
          <DecisionForm registrationDetails={registrationDetails} emailTemplate={emailTemplate} />
        </>
      )}
    </ContentSection>
  );
}

function DecisionForm({
  registrationDetails,
  emailTemplate,
}: {
  registrationDetails: RegistrationDetailsDto;
  emailTemplate: EmailTemplateDto;
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { decision, registrationId } = useRequiredParams<Params>();
  const initialAcceptanceDate =
    decision === RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE
      ? new UTCDateMini(new UTCDateMini().getFullYear() + 1, 0, 1).toISOString().slice(0, 10)
      : new UTCDateMini().toISOString().slice(0, 10);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    acceptanceDate: string;
    internalComment: string;
    sendEmail: boolean;
    emailContent: string;
  }>({
    defaultValues: {
      sendEmail: true,
      internalComment: registrationDetails.state?.stateData.internalComment ?? '',
      emailContent:
        decision !== RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE
          ? emailTemplate.content
          : emailTemplate.content.replace('${date}', formatDate(parseISO(initialAcceptanceDate)) ?? ''),
      acceptanceDate: initialAcceptanceDate,
    },
  });
  const sendEmailValue = watch('sendEmail');

  const emailContentValue = watch('emailContent');
  const dateValue = watch('acceptanceDate');
  const previousDate = usePreviousDate(dateValue);
  useEffect(() => {
    if (decision !== RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE) {
      return;
    }
    if (dateValue && previousDate && previousDate !== dateValue) {
      const previousDateString = formatDate(parseISO(previousDate));
      const dateString = formatDate(parseISO(dateValue));
      if (previousDateString && dateString) {
        setValue('emailContent', emailContentValue.replace(previousDateString, dateString));
      }
    }
  }, [dateValue, setValue, emailContentValue, decision, previousDate]);

  const setDecisionMutation = useSetDecision({
    mutation: {
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: getGetRegistrationDetailsByIdQueryKey(registrationId) });
        navigate('/');
      },
      onError: () => toast.error('Beim Versenden ist ein unerwarteter Fehler aufgetreten'),
    },
  });

  return (
    <form
      noValidate
      onSubmit={handleSubmit((data) =>
        setDecisionMutation.mutate({
          data: {
            registrationId: registrationId,
            stateDataDto: { acceptedFrom: data.acceptanceDate, internalComment: data.internalComment },
            status: decision,
            email: data.sendEmail ? { subject: emailTemplate.subject, content: data.emailContent } : null,
          },
        }),
      )}>
      <FormGrid>
        {decision !== RegistrationDtoVerificationState.MODEL_WRONG &&
          decision !== RegistrationDtoVerificationState.UNCLEAR && (
            <FormControl>
              <FormLabel>
                <Typography>Aufnahme per</Typography>
              </FormLabel>
              <Input
                disabled={decision !== RegistrationDtoVerificationState.MODEL_CORRECT_DOCTOR_CORRECT_IN_FUTURE}
                type={'date'}
                {...register('acceptanceDate')}
              />
            </FormControl>
          )}
        <FormControl>
          <FormLabel>
            <Typography>Kommentar intern</Typography>
          </FormLabel>
          <Textarea minRows={3} {...register('internalComment')} />
        </FormControl>
        <Checkbox
          defaultChecked
          sx={{ gridColumn: '1 / span 2' }}
          label={'E-Mail versenden'}
          {...register('sendEmail')}
        />
        {sendEmailValue && (
          <FormControl sx={{ gridColumn: '1 / span 2' }}>
            <FormLabel>
              <Typography>
                Mail an {registrationDetails.registration.firstName} {registrationDetails.registration.lastName} -
                Betreff: {emailTemplate.subject}
              </Typography>
            </FormLabel>
            <Textarea minRows={10} {...register('emailContent', { required: true })} />
            {errors.emailContent && <FormHelperText>E-Mail Inhalt muss ausgefüllt werden.</FormHelperText>}
          </FormControl>
        )}
        <Button type="submit">{sendEmailValue ? 'E-Mail abschicken' : 'Entscheidung speichern'}</Button>
      </FormGrid>
    </form>
  );
}

function usePreviousDate(value: string) {
  const ref = useRef<string>();
  useEffect(() => {
    // only update if value is defined, prevents update to invalid date
    if (value) {
      ref.current = value;
    }
  }, [value]);
  return ref.current;
}

const InformationBanner = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-block: 10px;
  gap: ${theme.spacing(3)};
`;

const FormGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${theme.spacing(3)};
`;
