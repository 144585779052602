import { useParams } from 'react-router-dom';

export function useRequiredParams<T extends Record<string, string | undefined>>() {
  const params = useParams();
  return new Proxy(params, {
    get(target, paramName) {
      if (paramName in target && typeof paramName === 'string') {
        const param = target[paramName];
        if (param) {
          return param;
        }
      }
      throw new Error(`param ${paramName.toString()} not found`);
    },
  }) as T;
}
