/**
 * HAM API – Generated by orval 🍺
 * Do not edit manually.
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { BodyType, ErrorType } from '../../axios';
import { request } from '../../axios';
import type { DecisionDto, RegistrationDetailsDto } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const setDecision = (decisionDto: BodyType<DecisionDto>, options?: SecondParameter<typeof request>) => {
  return request<void>(
    {
      url: `/intranet-api/registration-decision`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: decisionDto,
    },
    options,
  );
};

export const getSetDecisionMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setDecision>>,
    TError,
    { data: BodyType<DecisionDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof setDecision>>, TError, { data: BodyType<DecisionDto> }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof setDecision>>, { data: BodyType<DecisionDto> }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return setDecision(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetDecisionMutationResult = NonNullable<Awaited<ReturnType<typeof setDecision>>>;
export type SetDecisionMutationBody = BodyType<DecisionDto>;
export type SetDecisionMutationError = ErrorType<unknown>;

export const useSetDecision = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setDecision>>,
    TError,
    { data: BodyType<DecisionDto> },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<Awaited<ReturnType<typeof setDecision>>, TError, { data: BodyType<DecisionDto> }, TContext> => {
  const mutationOptions = getSetDecisionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getRegistrationDetailsById = (
  registrationId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<RegistrationDetailsDto>(
    { url: `/intranet-api/registration-decision/registration/${registrationId}`, method: 'GET', signal },
    options,
  );
};

export const getGetRegistrationDetailsByIdQueryKey = (registrationId: string) => {
  return [`/intranet-api/registration-decision/registration/${registrationId}`] as const;
};

export const getGetRegistrationDetailsByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getRegistrationDetailsById>>,
  TError = ErrorType<unknown>,
>(
  registrationId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRegistrationDetailsById>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRegistrationDetailsByIdQueryKey(registrationId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegistrationDetailsById>>> = ({ signal }) =>
    getRegistrationDetailsById(registrationId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!registrationId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRegistrationDetailsById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRegistrationDetailsByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationDetailsById>>>;
export type GetRegistrationDetailsByIdQueryError = ErrorType<unknown>;

export const useGetRegistrationDetailsById = <
  TData = Awaited<ReturnType<typeof getRegistrationDetailsById>>,
  TError = ErrorType<unknown>,
>(
  registrationId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRegistrationDetailsById>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRegistrationDetailsByIdQueryOptions(registrationId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
